import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

const App = () => {

  const [listadoRecursos, setListadoRecursos] = useState([]);

  useEffect(() => {
    const instance = axios.create({
      baseURL: "https://api.hstprototype.com/api",
      timeout: 10000,
      //headers: {'Authorization': 'Bearer '+userData.token}
  });
  
  trackPromise(
          
      instance.get("/recursos")
      .then(response => {
        setListadoRecursos(response.data)
      })
      .catch(e => {
          //setIsAuthorized(false);
          console.log("Expired token - Error #45435");
      })

  );
  }, [])

  return (
    <Container>
      <Row>
      <h1 className='mb-4 mt-4'>Listado de recursos</h1>
      {
        listadoRecursos && listadoRecursos.map(post =>{
          const {id, title, description, status, fecha} = post;

          return (
            <div className='mb-5' key={id}>
              <h3>{title}</h3>
              <p>
                Descripción: {description}
              </p>
              <p>
                Status: {status}
              </p>
              <p>
                Fecha: {fecha}
              </p>
            </div>
          )
        })
      }
      </Row>
    </Container>
  )
}
export default App;
